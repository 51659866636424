import React from 'react';

import TechTemplate from '../technologies-template/technologies-template';
import Styles from './technologies-container.module.scss';

export default ( props ) => (

  <section className={Styles.tech}>
    <h2 className={Styles.sectionTitle}>Technologies we use</h2>
    <div className="container">
      <p className={Styles.text}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
      </p>
    </div>
    <div className={Styles.techContainer}>
      {Object.entries(props).map( ( [key, value] ) => 
        <TechTemplate key={key} {...value}/>
      )}
    </div>
  </section>
);
