import React from 'react';
import { Brain, Rocket, Bottle } from '../service-icons/service-icons';
import { White } from '../watermark/watermark';
import { FaCircle } from "react-icons/fa";

import Styles from './services-component.module.scss';

export default () => (
  
  <section className={Styles.services}>

      <div className={Styles.mark}>
        <White />
      </div>

    <div className="container">
      <h2 className={Styles.sectionTitle}>Services</h2>

      <div className={Styles.mainContainer}>
        <div className={Styles.brainContainer}>
          <div className={Styles.mainIcon}>
            <Brain />
          </div>
          <h4 className={Styles.title}>Strategy &amp; Data Analysis</h4>
          <p className={Styles.text}>
            We focus on user insight and data to map your business requirements
            to deliver best creative and technical solutions. Our digital
            strategy is to help brands get more visibility and consume
          </p>
          <ul>
            <li className={Styles.line}>
              <FaCircle />Data strategy and reporting
            </li>
            <li className={Styles.line}>
              <FaCircle />User insights
            </li>
            <li className={Styles.line}>
              <FaCircle />Digital Strategy &amp; transformation
            </li>
          </ul>
        </div>
        <div className={Styles.rocketContainer}>
          <div className={Styles.mainIcon}>
            <Rocket />
          </div>
          <h4 className={Styles.title}>User Experience &amp; creative</h4>
          <p className={Styles.text}>
            We deliver compelling user experience and design through data 
            insights and information we gather from user research.
          </p>
          <ul>
            <li className={Styles.line}>
              <FaCircle />Wireframing &amp; Protoyping
            </li>
            <li className={Styles.line}>
              <FaCircle />User Journey &amp; content audit
            </li>
            <li className={Styles.line}>
              <FaCircle />Information architecture
            </li>
            <li className={Styles.line}>
              <FaCircle />Concept design &amp; validation
            </li>
            <li className={Styles.line}>
              <FaCircle />Brand Identity
            </li>
          </ul>
        </div>
        <div className={Styles.bottleContainer}>
          <div className={Styles.mainIcon}>
            <Bottle />
          </div>
          <h4 className={Styles.title}>Technology &amp; platform</h4>
          <p className={Styles.text}>
            We build and automate immersive web experience through digital
            omni-channels. We deliver technical solutions in latest
            technologies and platforms to build scalable digital marketing
            solution.
          </p>
          <ul>
            <li className={Styles.line}>
              <FaCircle />e-Commerce frameworks
            </li>
            <li className={Styles.line}>
              <FaCircle />API &amp; web services integration
            </li>
            <li className={Styles.line}>
              <FaCircle />Full-stack technical development
            </li>
            <li className={Styles.line}>
              <FaCircle />Mobile and Tablet Apps design &amp; development
            </li>
            <li className={Styles.line}>
              <FaCircle />Optimisation and testing
            </li>
          </ul>
        </div>
      </div>

    </div>
  </section>
);