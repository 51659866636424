import React from 'react';
import { Link } from 'gatsby';
import { IoIosArrowDown } from "react-icons/io";

import Styles from './banner.module.scss';

export default () => (

  <section className={Styles.banner}>
    <div className={Styles.video}>
      <video 
        loop
        autoPlay
        playsInline
        muted
      >
        <source 
          src="https://radiumagency.com/assets/digitalWorld.mp4"  
          type="video/mp4"
        />
      </video>
    </div>

    <div className={Styles.overlay}></div>

    <div className={Styles.bannerContainer}>
      <div className="container">
        <h1 className={Styles.title}>
          <span className={Styles.titleColor}>We are</span> a technology startup agency based in London
        </h1>
        <p className={Styles.text}>
          Passionate about data, design and development
        </p>
        <div className={Styles.buttonContainer}>
          <Link 
            to="/clients"
            className={`${Styles.buttonFull} button--pulse`}
          >
            Our Work
          </Link>
          <Link 
            to="/about"
            className={`${Styles.buttonEmpty} button--pulse`}
          >
            Our Work
          </Link>
        </div>
      </div>
    </div>
    
    <div className={Styles.scrollDown}>
      <IoIosArrowDown />
    </div>
  </section>
);