import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Banner from '../components/banner/banner';
import ClientsContainer from '../components/clients-container/clients-container';
import Services from '../components/services-component/services-component';
import Technologies from '../components/technologies-container/technologies-container';
import Testimonials from '../components/testimonials-container/testimonials-container';

export default ( props ) => {

  /**
   * Client data block
   */
  const clientsData = {

    home: true,

    hidden: [
      {
        mainImg: <Img alt="rsmd" fluid={props.data.rsmd.childImageSharp.fluid}/>,
        logo: <Img alt="rsmd logo" fluid={props.data.rsmd_logo.childImageSharp.fluid}/>,
        link: 'rsmd',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        text: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        companyLink: 'https://www.rsmd.london/'
      },
      {
        mainImg:  <Img alt="cps" fluid={props.data.canon_cps.childImageSharp.fluid}/>,
        logo: <Img alt="canon logo" fluid={props.data.canon_logo.childImageSharp.fluid}/>,
        link: 'cps',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        text: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        companyLink: 'https://www.canon-europe.com/pro/canon-professional-services/'
      }
    ],
    view: [
      {
        mainImg: <Img alt="pro" fluid={props.data.canon_pro.childImageSharp.fluid}/>,
        logo: <Img alt="canon logo" fluid={props.data.canon_logo.childImageSharp.fluid}/>,
        link: 'pro',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        text: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        companyLink: 'https://www.canon-europe.com/pro'
      },
      {
        mainImg:  <Img alt="usi" fluid={props.data.usimoney.childImageSharp.fluid}/>,
        logo: <Img alt="usi logo" fluid={props.data.usi_logo.childImageSharp.fluid}/>,
        link: 'usimoney',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        text: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        companyLink: 'https://www.usimoney.com/'
      },
    ]
  };

  /**
   * Tech data block
   */
  const techData = {

    php: {
      image: <Img alt="php" fixed={props.data.php.childImageSharp.fixed}/> 
    },
    react: {
      image: <Img alt="react" fixed={props.data.react.childImageSharp.fixed}/>
    },
    wordpress: {
      image: <Img alt="wordpress" fixed={props.data.wordpress.childImageSharp.fixed}/>
    },
    css: {
      image: <Img alt="css" fixed={props.data.css.childImageSharp.fixed}/>
    },
    js: {
      image: <Img alt="js" fixed={props.data.js.childImageSharp.fixed}/>
    },
    html: {
      image: <Img alt="html" fixed={props.data.html.childImageSharp.fixed}/>
    }
  };

  /**
   * Testimonials data block
   */
  const testimonialsData = [

    {
      mainImg: <Img alt="avatar" fluid={props.data.avatar.childImageSharp.fluid}/>,
      name: 'Marcin Trojanowski',
      position: 'Product Designer',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
    },
    {
      mainImg: <Img alt="avatar" fluid={props.data.avatar.childImageSharp.fluid}/>,
      name: 'Marcin Trojanowski',
      position: 'Product Designer',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
    },
    {
      mainImg: <Img alt="avatar" fluid={props.data.avatar.childImageSharp.fluid}/>,
      name: 'Marcin Trojanowski',
      position: 'Product Designer',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
    },
    {
      mainImg: <Img alt="avatar" fluid={props.data.avatar.childImageSharp.fluid}/>,
      name: 'Marcin Trojanowski',
      position: 'Product Designer',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
    }
  ];
  
  return (
    <Layout>
      <SEO 
        title="Home" 
        keywords={[`digital`, `agency`, `technology`, `Radium`]} 
      />
      <Banner />
      <ClientsContainer {...clientsData}/>
      <Services />
      <Technologies {...techData}/>
      <Testimonials {...testimonialsData}/>
    </Layout>
  )
};

/**
 * Image query's
 */
export const query = graphql`
  query {
    wordpress: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "wordpress-logo.png" }) {
      ...fixedSmall
    }
  
    css: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "css.png" }) {
      ...fixedSmall
    }
  
    php: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "php.png" }) {
      ...fixedSmall
    }
  
    react: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "react.png" }) {
      ...fixedSmall
    }

    html: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "html.png" }) {
      ...fixedSmall
    }

    js: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "js.png" }) {
      ...fixedSmall
    }

    logo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "radium-white.png" }) {
      ...fluidSmall
    }

    avatar: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "avatar.jpeg" }) {
      ...fluidAvatar
    }

    canon_logo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "canon-logo.png" }) {
      ...fluidNormal
    }

    rsmd_logo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "rsmd-logo.png" }) {
      ...fluidNormal
    }

    usi_logo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "usi-logo.png" }) {
      ...fluidNormal
    }

    canon_pro: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "pro.png" }) {
      ...fluidNormal
    }

    canon_cps: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "cps.png" }) {
      ...fluidNormal
    }

    rsmd: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "rsmd.png" }) {
      ...fluidNormal
    }

    usimoney: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "usi.png" }) {
      ...fluidNormal
    }
  }
`;

/**
 * Image format's
 */
export const fixedSmall = graphql`
fragment fixedSmall on File {
  childImageSharp {
    fixed(width: 120, height: 120) {
      ...GatsbyImageSharpFixed
    }
  }
}
`;
export const thumbnail = graphql`
fragment thumbnail on File {
  childImageSharp {
    fixed(width: 60, height: 60) {
      ...GatsbyImageSharpFixed
    }
  }
}
`;

export const fixedMedium = graphql`
fragment fixedMedium on File {
  childImageSharp {
    fixed(width: 200, height: 140) {
      ...GatsbyImageSharpFixed
    }
  }
}
`;

export const fluidSmall = graphql`
fragment fluidSmall on File {
  childImageSharp {
    fluid(maxWidth: 150) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const fluidAvatar = graphql`
fragment fluidAvatar on File {
  childImageSharp {
    fluid(maxWidth: 100, maxHeight: 100) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const fluidNormal = graphql`
fragment fluidNormal on File {
  childImageSharp {
    fluid{
      ...GatsbyImageSharpFluid
    }
  }
}
`;
